@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  background-image: linear-gradient(90deg, rgb(6, 147, 227) 29%, rgb(155, 81, 224) 100%) !important;
  background-color: rgb(236, 230, 220) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-attachment: scroll !important;
  width: 100%;
  /* Adjust the opacity as needed */
  z-index: -1 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'gotham-rounded-bold';
  src: local('gotham-rounded-bold'), url(./fonts/gotham-rounded-bold.otf) format('opentype');
}

@font-face {
  font-family: 'CedarvilleCursive-Regular';
  src: local('CedarvilleCursive-Regular'), url(./fonts/CedarvilleCursive-Regular.ttf) format('opentype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.65;
  }
  100% {
    opacity: 1;
  }
}

.intermitente {
  animation: blink 1.5s infinite;
}

@keyframes jump {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounceObj {
  animation: jump 2s 2 cubic-bezier(0.25, 0.84, 0.44, 1);
}